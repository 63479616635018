import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "./api";
const apiUrl = process.env.REACT_APP_API_URL;

function Puzzle() {
  const { puzzle_id } = useParams();
  const [getPuzzle, setGetPuzzle] = useState({});

  const gridSize = 15; // Set the grid size to 15x15

  const gridStyle = {
    gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
  };

  useEffect(() => {
    api
      .get(`${apiUrl}/puzzle/${puzzle_id}`)
      .then((response) => {
        console.log(response.data);
        setGetPuzzle({
          status: response.status,
          puzzle: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }, []);

  return getPuzzle.status === 200 ? (
    <>
      <div className="container mx-auto p-4">
        <div className="grid" style={gridStyle}>
          {getPuzzle.puzzle.grid.map((cell, index) => (
            <div
              key={index}
              className={`puzzle-cell relative text-center ${
                cell === "." ? "bg-black" : "bg-gray-200"
              } ${cell === "." ? "no-border" : "border border-black"} p-1`}
            >
              {cell !== "." && cell}
              {getPuzzle.puzzle.gridnums[index] !== 0 && (
                <span className="absolute top-0 left-0 font-bold text-gray-500 ml-1 mt-1" style={{ fontSize: '0.5rem' }}>
                  {getPuzzle.puzzle.gridnums[index]}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <p></p>
  );
  // ({getPuzzle.status == 200 && });
}

export default Puzzle;
