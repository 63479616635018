import LogoutInterceptor from "./LogoutInterceptor";
import Main from "./Main.js";
import Puzzle from "./Puzzle.js";
import { LoginProvider } from "./LoginContext";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="flex h-screen bg-[#282c34]">
        <div className="m-auto w-1/3">
          <Main />
        </div>
      </div>
    ),
  },
  {
    path: "/puzzle/:puzzle_id",
    element: (
      <div className="flex h-screen bg-[#282c34]">
        <div className="m-auto w-1/3">
          <Puzzle />
        </div>
      </div>
    ),
  },
]);

const App = () => {
  return (
    <LoginProvider>
      <LogoutInterceptor />
      <RouterProvider router={router} />
    </LoginProvider>
  );
};

export default App;
