import Login from "./Login.js";
import { LoginContext } from "./LoginContext";
import React, { useEffect, useState, useContext } from "react";
import api from "./api";
import { Skeleton, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
const apiUrl = process.env.REACT_APP_API_URL;

function AnswerDetails({}) {}

function AnswerList({ answers, selectedAnswer, setSelectedAnswer }) {
  const [getPartners, setGetPartners] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useContext(LoginContext);

  const handleClick = (answer) => {
    console.log("answer", answer);
    if (selectedAnswer === answer) {
      setSelectedAnswer(null);
    } else {
      setIsLoading(true);
      setSelectedAnswer(answer);
      api
        .get(`${apiUrl}/answer/${answer.text}`)
        .then((response) => {
          setGetPartners({
            status: response.status,
            partners: JSON.parse(response.data),
          });
        })
        .catch((error) => {
          if (error.response.status >= 400 && error.response < 500) {
            logout();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleBackClick = (answer) => {
    setSelectedAnswer(null);
  };

  if (selectedAnswer) {
    return (
      <div>
        <div className="flex items-center">
          <div className="mr-auto">
            <IconButton
              onClick={() => handleBackClick(selectedAnswer)}
              color="inherit"
            >
              <ArrowBack />
            </IconButton>
          </div>
          <div className="flex-grow text-center pr-10 text-lg font-bold">
            {selectedAnswer.text}
          </div>
        </div>
        <div
          style={{ maxHeight: "calc(100vh - 200px)" }}
          className="overflow-y-auto"
        >
          {isLoading ? (
            <>
              <div className="bg-gray-100 p-4 mb-2 rounded-md shadow-md">
                <div className="flex justify-center items-center mb-2">
                  <Skeleton
                    sx={{ bgcolor: "gray.300" }}
                    variant="text"
                    width={120}
                    height={20}
                  />
                </div>
                <div className="flex justify-center items-center mb-2">
                  <Skeleton
                    sx={{ bgcolor: "gray.300" }}
                    variant="text"
                    width={150}
                    height={20}
                  />
                </div>
              </div>
              <div className="bg-gray-100 p-4 mb-4 rounded-md shadow-md">
                <div className="flex justify-center items-center mb-2">
                  <Skeleton
                    sx={{ bgcolor: "gray.300" }}
                    variant="text"
                    width={120}
                    height={20}
                  />
                </div>
                <div className="flex justify-center items-center mb-2">
                  <Skeleton
                    sx={{ bgcolor: "gray.300" }}
                    variant="text"
                    width={150}
                    height={20}
                  />
                </div>
              </div>
              <div className="bg-gray-100 p-4 mb-4 rounded-md shadow-md">
                <div className="flex justify-center items-center mb-2">
                  <Skeleton
                    sx={{ bgcolor: "gray.300" }}
                    variant="text"
                    width={120}
                    height={20}
                  />
                </div>
                <div className="flex justify-center items-center mb-2">
                  <Skeleton
                    sx={{ bgcolor: "gray.300" }}
                    variant="text"
                    width={150}
                    height={20}
                  />
                </div>
              </div>
            </>
          ) : (
            getPartners.status === 200 &&
            getPartners.partners.answers
              .reduce((acc, item) => {
                const existingClueIndex = acc.findIndex(
                  (el) => el.clue === item.clue
                );

                if (existingClueIndex !== -1) {
                  acc[existingClueIndex].puzzles.push(item);
                } else {
                  acc.push({
                    clue: item.clue,
                    puzzles: [item],
                  });
                }

                return acc;
              }, [])
              .sort((a, b) => b.puzzles.length - a.puzzles.length)
              .map((group, idx) => (
                <ul
                  key={idx}
                  className="bg-gray-100 p-2 mb-2 rounded-md shadow-md"
                >
                  <li className="text-lg font-bold">{group.clue}</li>
                  {group.puzzles.map((puzzle, puzzleIdx) => (
                    <span className="text-gray-600" key={puzzleIdx}>
                      <a
                        class="hover:text-blue-500 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                        href={`/puzzle/${puzzle.puzzle_id}`}
                      >
                        {puzzle.puzzle}
                      </a>
                      {puzzleIdx !== group.puzzles.length - 1 && ", "}
                    </span>
                  ))}
                </ul>
              ))
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "calc(100vh - 200px)" }}
      className="overflow-y-auto"
    >
      <ul className="text-lg">
        {answers.map((answer, index) => (
          <li
            key={index}
            className="p-3 cursor-pointer hover:bg-gray-100"
            onClick={() => handleClick(answer)}
          >
            {answer.text}
          </li>
        ))}
      </ul>
    </div>
  );
}

function Main() {
  const [getAnswers, setGetAnswers] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { logout, isLoggedIn } = useContext(LoginContext);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchTerm.length < 3) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setSelectedAnswer(false);
      setIsLoading(true);
      api
        .get(`${apiUrl}/answers/${searchTerm}`)
        .then((response) => {
          setGetAnswers({
            status: response.status,
            answers: JSON.parse(response.data),
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm, logout]);
  if (!isLoggedIn)
    return (
      <>
        <Login />
      </>
    );

  return (
    <div
      style={{ height: "calc(100vh - 30px)" }}
      className="bg-white shadow-md rounded px-8 pt-6 mt-3 pb-8 mb-4"
    >
      <div>
        <input
          className="border-2 border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 text-center text-lg"
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="text-center mt-2">
        {isLoading ? (
          <div>
            <div className="block mb-2 text-center">
              <Skeleton
                sx={{ bgcolor: "gray.300" }}
                variant="text"
                width={120}
                height={40}
                className="mx-auto"
              />
              <Skeleton
                sx={{ bgcolor: "gray.300" }}
                variant="text"
                width={120}
                height={40}
                className="mx-auto"
              />
              <Skeleton
                sx={{ bgcolor: "gray.300" }}
                variant="text"
                width={120}
                height={40}
                className="mx-auto"
              />
              <Skeleton
                sx={{ bgcolor: "gray.300" }}
                variant="text"
                width={120}
                height={40}
                className="mx-auto"
              />
            </div>
          </div>
        ) : getAnswers.status === 200 ? (
          <AnswerList
            answers={getAnswers.answers}
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
          />
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}

export default Main;
