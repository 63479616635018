import api from './api';
import { useContext } from 'react';
import { LoginContext } from './LoginContext';

const LogoutInterceptor = () => {
  const { logout } = useContext(LoginContext);

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      }

      return Promise.reject(error);
    }
  );

  return null; // This component doesn't render anything
};

export default LogoutInterceptor;
